// npm
import React, { memo } from 'react'
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Grid, styled, useTheme } from '@mui/material'

// components
import { theme } from '@molecules/sections/components/SectionImageAndText'

interface StandardProps {
  image: IGatsbyImageData
  children: React.ReactNode
  theme: theme
  altLayout: boolean
}

const Standard = ({ theme, children, image, altLayout }: StandardProps) => {
  const muiTheme = useTheme()
  return (
    <Box position="relative" {...theme}>
      <Container
        maxWidth="xl"
        sx={{
          [muiTheme.breakpoints.up('lg')]: {
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            display: 'flex',
            alignItems: 'center',
          },
          [muiTheme.breakpoints.down('lg')]: {
            padding: 0,
          },
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{ alignItems: { xs: 'flex-start', lg: 'stretch' } }}
        >
          <Grid item xs={12} lg={6} xl={6} ml={{ lg: altLayout ? 'auto' : 0 }}>
            <GatsbyImage image={image} alt="" />
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={0} sx={{ width: '100%' }}>
        <Grid item xs={12} lg={6} xl={6} ml={{ lg: altLayout ? 0 : 'auto' }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(Standard)
